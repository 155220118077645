<template>
  <img :alt="image?.alt" :src="src" :width="width" :height="height" />
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { Asset } from '@/types/storyblok';
import { type ImageServiceOptions, useStoryblokImageService } from '@/utils/storyblok/image';

const props = defineProps({
  image: { type: Object as PropType<Asset> },
  width: Number,
  height: Number,
  resize: { type: Boolean, default: false },
  smart: { type: Boolean, default: false },
  grayscale: { type: Boolean, default: false },
});

const src = computed(() => {
  const options = {} as ImageServiceOptions;
  if (props.resize) {
    options.resize = {
      type: props.smart ? 'smart' : 'static',
      width: props.width ?? 'auto',
      height: props.height ?? 'auto',
    };
  }
  if (props.grayscale) {
    options.filters = ['grayscale()'];
  }
  return useStoryblokImageService(props.image?.filename as string, options);
});
</script>
